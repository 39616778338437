import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

import AppStoreBadge from "../assets/AppStoreBadge.svg"
import GooglePlayBadge from "../assets/GooglePlayBadge.svg"
import Logo from "../assets/Logo.svg"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import devices from "../helpers/devices"
import { PageProps } from "../helpers/props"

const Hero = styled.section`
  height: calc(100vh - 80px);
  padding: 1.5rem 1.5rem;

  .splash {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      max-width: 80%;
      margin-bottom: 2rem;

      @media ${devices.mobileLandscape} {
        max-height: 42%;
        margin-bottom: 1rem;
      }

      @media ${devices.tablet} {
        max-width: 28rem;
        margin-bottom: 3rem;
      }

      @media ${devices.desktop} {
        max-width: 24rem;
      }
    }

    .title.is-1 {
      display: none;
    }

    .title.is-2 {
      color: var(--color-primary);
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: center;

      @media ${devices.tablet} {
        font-size: 2.75rem;
      }

      @media ${devices.desktop} {
        font-size: 2.25rem;
      }
    }

    .title.is-3 {
      color: var(--color-primary);
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: center;

      @media ${devices.tablet} {
        font-size: 1.8rem;
      }

      @media ${devices.desktop} {
        font-size: 1.5rem;
      }
    }

    .download-app {
      margin-bottom: 6.5rem;
      text-align: center;

      a:not(:last-child) {
        display: inline;
        margin-right: 1rem;
      }

      svg {
        height: 44px;
        width: auto;
      }

      @media ${devices.mobileLandscape} {
        margin-bottom: 1rem !important;
      }

      @media ${devices.mobileSE} {
        a:not(:last-child) {
          display: block;
          margin-right: 0;
        }
      }

      @media ${devices.tablet} {
        margin-bottom: 4rem;
      }

      @media ${devices.widescreen} {
        margin-bottom: 5rem;
      }

      @media ${devices.fullhd} {
        margin-bottom: 6rem;
      }
    }

    .discover {
      position: absolute;
      bottom: 1rem;
      background: none;
      border: none;
      color: var(--color-primary);
      cursor: pointer;
      z-index: 10;

      &:active,
      &:focus {
        background: none;
        border: none;
      }

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
`

const IndexPage: React.FunctionComponent<PageProps> = ({ location }) => {
  function onMoreButtonClick() {
    const e = document.querySelector("#footer")
    if (e) e.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <Meta pathname={location.pathname} title="Home" />
      <Navbar isBrandVisible={false} mustBlend />
      <Hero className="section is-clipped" id="hero">
        <div className="splash">
          <img alt="" className="logo" src={Logo} />
          <h1 className="title is-1">Pole Club Milano</h1>
          <h2 className="title is-2">
            Il primo, esclusivo club di pole dance, nel cuore di Milano
          </h2>
          <div className="download-app">
            <h3 className="title is-3">
              Scarica la nostra app POLE CLUB MILANO, guarda il calendario e
              iscriviti ai corsi
            </h3>
            <a
              href="https://apps.apple.com/it/app/pole-club-milano/id1535352950"
              rel="noreferrer"
              target="_blank"
              title="Scarica su App Store"
            >
              <img alt="Scarica su App Store" src={AppStoreBadge} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.guryou.poleclubmilano"
              rel="noreferrer"
              target="_blank"
              title="Disponibile su Google Play"
            >
              <img alt="Disponibile su Google Play" src={GooglePlayBadge} />
            </a>
          </div>
          <button
            aria-label="Scopri di più"
            className="discover"
            onClick={onMoreButtonClick}
            type="button"
          >
            <FontAwesomeIcon icon={faChevronCircleDown} />
          </button>
        </div>
      </Hero>
      {/* <section className="section" id="discover">
        <div className="container">
          <div className="columns">
            <div className="column">
              <iframe
                allowFullScreen
                height="582"
                seamless
                src="https://widget.guryou.com/website-integration/164"
                style={{ border: "none" }}
                title="guryou-widget"
                width="100%"
              >
                <p>Il tuo browser non supporta il nostro widget.</p>
              </iframe>
              <div className="has-text-centered">
                <p>
                  Nota: Per visualizzare i corsi è necessario aver abilitato i
                  cookie di terze parti.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </Layout>
  )
}

export default IndexPage
